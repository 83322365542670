$(document).ready(function(){
	$(window).load(function(){

		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		$('.menu-container .menu-contain').css({
			'padding-bottom' : $quickLinks,
			'padding-top' : $navHeight
		});

		$('.search-input').on('focus', function(){
			$('#listings').addClass('d-none');
			$('#search-listings').removeClass('d-none');
		});

		$(document).on('click', '.names', function(e){
		    e.preventDefault();
		    $(".items .user-item .controls .edit .edit-button").prop("checked", false);
		    $(this).siblings('.controls').find('.edit').find('.checkmark').trigger('click');
		});

		$(document).on('click', '.controls .edit .checkmark', function(e){
		    e.preventDefault();
		    $(".items .user-item .controls .edit .edit-button").prop("checked", false);
		    $(this).siblings('.edit-button').prop("checked", true);
		});

		$(document).on('click', '.menu-item a i', function(e){
		    e.preventDefault();
		    $(this).parent('a').siblings('.sub-menu').toggleClass('active');
		    $(this).parent('a').parent('.menu-item').toggleClass('active');
		});

		$('#menu-menu-admin .menu-item').each(function(){
		    if($(this).hasClass('menu-item-has-children')){
		        $(this).children('a').append('<i class="fas fa-caret-down"></i>');
		    }
		});
		$(document).on('click', '.csv-download', function(e){
			e.preventDefault();
			var dept = $(this).data('dept');
			var campus = $(this).data('campus');

			// console.log(dept);
			// console.log(campus);
			$.ajax({
				url: $('.ajaxlocation').data('ajaxlocation'),
				type: 'POST',
				data: {
					action: 'download_csv_file',
					data1: dept,
					data2: campus
				},
				beforeSend : function(){

				},
				success: function(response){
					console.log(response)
				}
			});
		});
		$(document).on('click','.manage-users-content .control-buttons .delete',function () {
			console.log('working');
			var idDelete = [];
			$(this).parent('.control-buttons').siblings('.overlay').find('.items').each(function(){
				if($(this).find('.delete-button').prop('checked') == true){
					idDelete.push($(this).data('id'));

				}
				else{
					console.log('no user selected');
				}
			});
			if (idDelete != '') {
				$.ajax({
					url : $('.ajaxlocation').data('ajaxlocation'),
					type : 'POST',
					data : {
						action : 'delete_user',
						id : JSON.stringify(idDelete)
					},
					beforeSend: function(){
						
						// $('body, html').css({
						// 	'overflow' : 'hidden'
						// });
						// $('.popup-modal-container').addClass('show');
						// $('html').addClass('popupshow');
						$('.manage-users-content .overlay .loader').addClass('show');
					},
					success: function(response) {
						$('.manage-users-content .overlay .loader').removeClass('show');
						location.reload();
					}
				}); 
		        // $('body').getNiceScroll().doScrollPos(0,$('#anchor-link').offset().top);

	        }
		});
		$(document).on('click','.manage-users-content .buttons',function () {
			var id = "";
			var idDelete = [];
			$(this).siblings('.overlay').find('.items').each(function(){
				if($(this).find('.edit-button').prop('checked') == true){
					id = $(this).data('id');
				}
				else{
					console.log('no user selected');
				}
			});
			
			if (id != '') {
				$.ajax({
					url : $('.ajaxlocation').data('ajaxlocation'),
					type : 'POST',
					data : {
						action : 'get_user_info',
						id : id
					},
					beforeSend: function(){
						
						// $('body, html').css({
						// 	'overflow' : 'hidden'
						// });
						// $('.popup-modal-container').addClass('show');
						// $('html').addClass('popupshow');
						$('.manage-users-content .overlay .loader').addClass('show');
					},
					success: function(response) {
						$('.manage-users-content .overlay .loader').removeClass('show');
						var myArray = jQuery.parseJSON(response);
						console.log(myArray);
						$('#anchor-link .overlay form #id').val(myArray.ID);
						$('#anchor-link .overlay form #username').val(myArray.nicename);
						$('#anchor-link .overlay form #firstname').val(myArray.firstname);
						$('#anchor-link .overlay form #lastname').val(myArray.lastname);
						$('#anchor-link .overlay form #email').val(myArray.email);
						$('#anchor-link .overlay form #email').val(myArray.email);					
					}
				});

		        $('body').getNiceScroll().doScrollPos(0,$('#anchor-link').offset().top);

	        }
	        
	        console.log(idDelete);
	    });

	    $(document).on('submit', '#form-update', function(e){
	    	e.preventDefault();
	    	var id = $('#id').val();
	    	var email = $('#email').val();
	    	var firstname = $('#firstname').val();
	    	var lastname = $('#lastname').val();
	    	var username = $('#username').val();
	    	var password = $('#password').val();
	    	var arr = [];
	    	if (id != ''){
	    		arr.push({'id':id});
	    	}
	    	if (email != ''){
	    		arr.push({'email':email});
	    	}
	    	if (firstname != ''){
	    		arr.push({'firstname':firstname});
	    	}
	    	if (lastname != ''){
	    		arr.push({'lastname':lastname});
	    	}
	    	if (username != ''){
	    		arr.push({'username':username});
	    	}
	    	if (password != ''){
	    		arr.push({'password':password});
	    	}
	    	$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				data : {
					action : 'update_user_info',
					arr : JSON.stringify(arr)
				},
				beforeSend: function(){
					console.log(arr);
					$('.manage-users-content .overlay .loader').addClass('show');
				},
				success: function(response) {
					console.log(response);
					// $('.manage-users-content .overlay .loader').removeClass('show');
					location.reload();
				}
			});
	    });

		$(document).on('click', '.mobile-menu.notAnimated', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$(this).removeClass('notAnimated').addClass('animated');
			$('.menu-container').addClass('show');
			setTimeout(function(){
				$('.menu-container').addClass('animate');
			}, 500);
		});
		// $(document).on('gform_confirmation_loaded', function(){
  //           alert('working');
  //       });

		$(document).on('click', '.mobile-menu.animated', function(){
			$(this).removeClass('animated').addClass('notAnimated');

			$('.menu-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.menu-container').removeClass('show');
			}, 500);

			setTimeout(function(){
				$('.menu-container .menu-contain nav ul li .sub-menu').removeClass('show');
			}, 700);

		});

		$(document).on('click', '.submenu-button', function(){
			$(this).parent('li').find(' > .sub-menu').addClass('show');
		});
		$(document).on('click', '.submenu-close-button', function(){
			$(this).parent('li').parent('ul').removeClass('show');
		});

		$(document).on('click', 'a[href^="mailto:"].main', function(e){
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.mailto-container').addClass('show');
			setTimeout(function(){
				$('.mailto-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.mailto-close-button', function(){
			$('.mailto-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.mailto-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '.search-btn', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});
			$('.search-form-template').addClass('show');
			setTimeout(function(){
				$('.search-form-template').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.search-close-button', function(){
			$('body, html').removeAttr('style');
			$('.search-form-template').removeClass('show');
			setTimeout(function(){
				$('.search-form-template').removeClass('animate');
			}, 300);
		});

		if($('.home').is(':visible')){
			$('.announcement-modal-container').addClass('show');
			setTimeout(function(){
				$('.announcement-modal-container').addClass('animate');
			}, 300);
		}
		$(document).on('click', '.announcement-close', function(){
			$('body, html').removeAttr('style');
			$('header.site-header.push-top').removeAttr('style');
			$('.announcement-modal-container').removeClass('show');
			$('html').removeClass('popupshow');
			setTimeout(function(){
				$('.announcement-modal-container').removeClass('animate');
			}, 300);
		});

		$(document).on('click', '.testimonial-item-contain', function(){
			$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_testimonial',
					id : $(this).data('id')
				},
				beforeSend: function(){
					$('body, html').css({
						'overflow' : 'hidden'
					});
					$('.popup-modal-container').addClass('show');
					$('html').addClass('popupshow');
				},
				success: function(response) {
					
					setTimeout(function(){
						$('.popup-modal-container').addClass('animate');
					}, 300);
					$('.popup-modal-container .popup-body').html(response.content);
			
				}
			});
		});

		$(document).on('click', '.popup-close', function(){
			$('body, html').removeAttr('style');
			$('header.site-header.push-top').removeAttr('style');
			$('.popup-modal-container').removeClass('show');
			$('html').removeClass('popupshow');
			setTimeout(function(){
				$('.popup-modal-container').removeClass('animate');
			}, 300);
		});


		$(document).on('click', '.apply-btn', function(e){
			var position = $(this).data('position');
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.career-container .yourPosition input').val(position);
			$('.career-container').addClass('show');
			setTimeout(function(){
				$('.career-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.career-close-button', function(){
			$('.career-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.career-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '#resumeBtn', function(){
			var input = $('.resume-container .resume input');
			input.click();
		});

		$('.resume-container .resume input').change(function(e){
			var FileName = e.target.files[0];
			$('#resumeBtn').addClass('active').text(FileName.name);
		});

		$(document).on('click', '.expand-term', function(e){
			e.preventDefault();
			$(this).toggleClass('fa-angle-down').toggleClass('fa-angle-up');
			$(this).parent('a').parent('.parent-term').find('.chid-term').first().toggleClass('active');
		});

		$(document).on('click', '.expand-selection-term', function(e){
			$('.archive-sidebar .term-container').toggleClass('active');
		});

		$(document).on('click', '.parent-backdrop .backdrop', function(){
			$('body, html').removeAttr('style');
			$(this).parent('.parent-backdrop').removeClass('show');
			setTimeout(function(){
				$(this).parent('.parent-backdrop').removeClass('animate');
			}, 300);
		});
	});
});